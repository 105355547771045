export const impactAccountsActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_MORE: 'LOAD_MORE',
  UPDATE_IS_LOGIN_ALLOWED: 'UPDATE_IS_LOGIN_ALLOWED',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_BID_LIMIT: 'UPDATE_BID_LIMIT',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
}

export const impactAccountsMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST_OPTS: 'SET_LIST_OPTS',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  UNSHIFT_LIST: 'UNSHIFT_LIST',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  UPDATE_IS_LOGIN_ALLOWED: 'UPDATE_IS_LOGIN_ALLOWED',
  UPDATE_CREDENTIALS: 'UPDATE_CREDENTIALS',
  UPDATE_BID_LIMIT: 'UPDATE_BID_LIMIT',
}

export const impactAccountsGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  LIST_OPTS: 'LIST_OPTS',
  LIST_RAW: 'LIST_RAW',
  PAGE_NUMBER: 'PAGE_NUMBER',
}
