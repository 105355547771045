import { validatePhone } from './validators'
import { validate as validateVin } from 'vin-validator'

import isEmpty from 'lodash/isEmpty'
import isDate from 'lodash/isDate'
import isNumber from 'lodash/isNumber'

export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 64

export const MIN_NAME_LENGTH = 1
export const MAX_NAME_LENGTH = 30

const YOUTUBE_REGEX = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/

export const required = val => ({
  isValid: !isEmpty(val) || isNumber(val) || isDate(val),
  translationId: 'VALIDATORS.REQUIRED'
})

export const requiredIf = predicate => (val, form) => ({
  isValid: !predicate(val, form) || required(val).isValid,
  translationId: 'VALIDATORS.REQUIRED'
})

export const min = min => (val, form) => ({
  isValid: Number(val) >= getValidatorValue(min, form),
  translationId: 'VALIDATORS.MIN'
})

export const max = max => (val, form) => ({
  isValid: Number(val) <= getValidatorValue(max, form),
  translationId: 'VALIDATORS.MAX'
})

export const minLength = minLength => (val, form) => ({
  isValid: !val || val.length >= getValidatorValue(minLength, form),
  translationId: 'VALIDATORS.MIN_LENGTH'
})

export const maxLength = maxLength => (val, form) => ({
  isValid: !val || val.length <= getValidatorValue(maxLength, form),
  translationId: 'VALIDATORS.MAX_LENGTH'
})

export const youtubeLink = val => ({
  isValid: !val || YOUTUBE_REGEX.test(val),
  translationId: 'VALIDATORS.YOUTUBE_LINK'
})

export const vin = val => ({
  isValid: !val || validateVin(val),
  translationId: 'VALIDATORS.VIN'
})

export const minCount = min => val => ({
  isValid: val.length >= min,
  translationId: 'VALIDATORS.MIN_COUNT'
})

export const password = val => {
  const minLengthResult = minLength(MIN_PASSWORD_LENGTH)(val)
  const maxLengthResult = maxLength(MAX_PASSWORD_LENGTH)(val)
  return minLengthResult.isValid ? maxLengthResult : minLengthResult
}

export const confirmPassword = newPassword => (val, form) => ({
  isValid: val === getValidatorValue(newPassword, form),
  translationId: 'VALIDATORS.CONFIRM_PASSWORD'
})

export const name = val => {
  const minLengthResult = minLength(MIN_NAME_LENGTH)(val)
  const maxLengthResult = maxLength(MAX_NAME_LENGTH)(val)
  return minLengthResult.isValid ? maxLengthResult : minLengthResult
}

export const phone = val => ({
  isValid: !val || validatePhone(val),
  translationId: 'VALIDATORS.PHONE'
})

export const url = val => ({
  isValid: !val || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
    .test(val),
  translationId: 'VALIDATORS.URL'
})

export const secondhandDealPermissionNumber = val => ({
  isValid: !val || /^[\dA-Za-z]{1,12}$/.test(val),
  translationId: 'VALIDATORS.SECONDHAND_DEAL_PERMISSION_NUMBER'
})

function getValidatorValue (raw, form) {
  return typeof raw === 'function' ? raw(form) : raw
}
