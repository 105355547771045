export const ALLOWED_ACCOUNT_COUNTRIES = Object.freeze([
  'ge',
  'us',
  'ua',
  'jo',
  'om',
  'ae',
])

export const ACCOUNTS_PAGE_LIMIT = 15

export const BID_LIMIT_CURRENCY = 'CAD'
export const MIN_BID_LIMIT = 0
export const MAX_LOGIN_LENGTH = 128
