<template>
  <list-grouped
    class="accounts-list"
    :list="list"
    :list-total-count="0"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="ACCOUNTS_PAGE_LIMIT"
    :load-more="loadMore"
    :head-component="AccountsListHead"
    :item-component="AccountItem"
    :item-skeleton-component="AccountItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import AccountItem from './AccountItem'
import AccountItemSkeleton from './AccountItemSkeleton'
import AccountsListHead from './AccountsListHead'

import { mapGetters } from 'vuex'
import { impactAccountsGetters } from '../store/types'

import { ACCOUNTS_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'accounts-list',
  components: { ListGrouped },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(ExtensionAccount),
    },

    loadMore: {
      type: Function,
      required: true,
    },
  },

  data () {
    return {
      AccountItem,
      AccountItemSkeleton,
      AccountsListHead,
      ACCOUNTS_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/impact-accounts', {
      isLoading: impactAccountsGetters.IS_LOADING,
      isNextLoading: impactAccountsGetters.IS_NEXT_LOADING,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts.scss";

.accounts-list {
  --list-padding-side: 3em;
}
</style>
