import { createFormSchema } from 'Utils/createFormSchema'
import {
  required,
  password,
  confirmPassword,
  maxLength,
  min,
} from 'Utils/formValidators'
import { MAX_LOGIN_LENGTH, MIN_BID_LIMIT } from '../constants'

export const createAccountFormSchema = createFormSchema({
  form: {
    login: '',
    country: '',
    bidLimit: 0,
    password: '',
    passwordConfirm: '',
  },

  validators: {
    login: [required, maxLength(MAX_LOGIN_LENGTH)],
    country: [required],
    bidLimit: [required, min(MIN_BID_LIMIT)],
    feeLevel: [required],
    password: [required, password],
    passwordConfirm: [
      required,
      confirmPassword(form => form.password)
    ]
  }
})
