<template>
  <div
    class="account-cell-login cells-cmn__cell"
    :class="{ 'account-cell-login_error': !account.isConnectorAvailable }"
  >
    <ui-icon
      v-if="!account.isConnectorAvailable"
      class="account-cell-login__icon"
      icon="exclamation"
    />

    <ui-icon
      v-else-if="account.isIosAllowed"
      class="account-cell-login__icon account-cell-login__icon_mobile"
      icon="mobile"
    />

    <p
      class="account-cell-login__id cells-cmn__str cells-cmn__str_nb"
      :title="account.id"
    >
      {{ account.id }}
    </p>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'account-cell-login',
  components: { UiIcon },

  props: {
    account: { type: ExtensionAccount, required: true },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.account-cell-login {
  color: $color-ui-default;

  &_error {
    color: $color-flag-is-error;
  }

  &__icon {
    font-size: 1.2em;

    &_mobile {
      color: $color-sys-success;
    }
  }

  &__id {
    color: inherit;
  }
}
</style>
