import { createFormSchema } from 'Utils/createFormSchema'
import {
  required,
  password,
  confirmPassword,
} from 'Utils/formValidators'

export const passwordFormSchema = createFormSchema({
  form: {
    password: '',
    passwordConfirm: '',
  },

  validators: {
    password: [required, password],

    passwordConfirm: [
      required,
      confirmPassword(form => form.password)
    ]
  }
})
