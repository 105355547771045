import get from 'lodash/get'
import { ModelBase } from './ModelBase'

export class ExtensionAccount extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.bidLimit = this._strFiat(raw.bidLimit)
    this.country = this._str(raw.country)
    this.feeLevel = this._str(raw.feeLevel)
    this.disablingReason = this._str(raw.disablingReason)

    this.isConnectorAvailable = Boolean(raw.isConnectorAvailable)
    this.isLoginAllowed = Boolean(raw.isLoginAllowed)
    this.isIosAllowed = Boolean(raw.isIosAllowed)

    const accountStats = get(raw, 'relationships.accountStats')
    this.identitiesCount = this._num(accountStats.identitiesCount)
    this.winnersCount = this._num(accountStats.winnersCount)
  }
}
