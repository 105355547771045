<template>
  <div class="op-edit-password">
    <button
      class="op-edit-password account-op account-op__btn"
      @click="showEditPasswordModal"
    >
      <ui-icon
        class="account-op__btn-ico"
        icon="lock"
      />

      <span class="account-op__btn-txt">
        {{ $t('CHANGE_PASSWORD_BTN') }}
      </span>
    </button>

    <op-edit-password-modal
      class="op-edit-password__modal"
      v-if="isEditPasswordModalShown"
      :account="account"
      @close="hideEditPasswordModal"
    />
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import OpEditPasswordModal from './OpEditPasswordModal'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'op-edit-password',
  components: {
    UiIcon,
    OpEditPasswordModal,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      isEditPasswordModalShown: false,
    }
  },

  methods: {
    onClick () {
      this.isEditPasswordModalShown = true
    },

    showEditPasswordModal () {
      this.isEditPasswordModalShown = true
      this.$emit('op-started')
    },

    hideEditPasswordModal () {
      this.isEditPasswordModalShown = false
      this.$emit('op-finished')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/accounts-operations.scss";
</style>

<i18n>
{
  "en": {
    "CHANGE_PASSWORD_BTN": "Change password"
  },
  "ka": {
    "CHANGE_PASSWORD_BTN": "პაროლის ცვლილება"
  },
  "ru": {
    "CHANGE_PASSWORD_BTN": "Сменить пароль"
  },
  "uk": {
    "CHANGE_PASSWORD_BTN": "Змінити пароль"
  }
}
</i18n>
