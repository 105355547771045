<template>
  <button
    class="op-block account-op account-op__btn"
    :disabled="isExecuting"
    @click="onClick"
  >
    <template v-if="account.isLoginAllowed">
      <icon-combo
        class="op-block__icon-combo account-op__btn-ico"
        icon="user"
        icon-addition="cross-bold"
      />

      <span class="account-op__btn-txt">
        {{ $t('BLOCK_BTN') }}
      </span>
    </template>

    <template v-else>
      <icon-combo
        class="op-block__icon-combo account-op__btn-ico"
        :class="'op-block__icon-combo_unblock'"
        icon="user"
        icon-addition="plus-bold"
      />

      <span class="account-op__btn-txt">
        {{ $t('UNBLOCK_BTN') }}
      </span>
    </template>

    <ui-spinner
      class="op-block__spinner account-op__overlay-spinner"
      v-if="isExecuting"
      type="pills"
      :overlay="true"
    />
  </button>
</template>

<script>
import { UiSpinner } from '@shelf.network/ui-kit'
import IconCombo from 'Common/IconCombo'

import { mapActions } from 'vuex'
import { impactAccountsActions } from '../../store/types'

import { ExtensionAccount } from 'Models/ExtensionAccount'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'op-block',
  components: {
    IconCombo,
    UiSpinner,
  },

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  data () {
    return {
      isExecuting: false,
    }
  },

  methods: {
    ...mapActions('ui/impact-accounts', {
      updateIsLoginAllowed: impactAccountsActions.UPDATE_IS_LOGIN_ALLOWED,
    }),

    async onClick () {
      if (this.isExecuting) return

      this.isExecuting = true
      try {
        await this.updateIsLoginAllowed({
          id: this.account.id,
          value: !this.account.isLoginAllowed
        })

        const successMsg = this.account.isLoginAllowed
          ? this.$t('ACCOUNT_UNBLOCKED_MSG', { account: this.account.id })
          : this.$t('ACCOUNT_BLOCKED_MSG', { account: this.account.id })
        showSuccess(successMsg)
      } catch (err) {
        console.error(err)
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
      }
      this.isExecuting = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/accounts-operations.scss";

.op-block {
  position: relative;

  &__icon-combo {
    --addition-color: #{$color-flag-is-error};

    &_unblock {
      --addition-color: #{$color-flag-is-success};
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BLOCK_BTN": "Block account",
    "UNBLOCK_BTN": "Unblock account",
    "ACCOUNT_BLOCKED_MSG": "Account {account} blocked",
    "ACCOUNT_UNBLOCKED_MSG": "Account {account} unblocked"
  },
  "ka": {
    "BLOCK_BTN": "დაბლოკვა",
    "UNBLOCK_BTN": "განბლოკვა",
    "ACCOUNT_BLOCKED_MSG": "ექაუნთი {account} დაიბლოკა",
    "ACCOUNT_UNBLOCKED_MSG": "ექაუნთი {account} განიბლოკა"
  },
  "ru": {
    "BLOCK_BTN": "Заблокировать аккаунт",
    "UNBLOCK_BTN": "Разблокировать аккаунт",
    "ACCOUNT_BLOCKED_MSG": "Аккаунт {account} заблокирован",
    "ACCOUNT_UNBLOCKED_MSG": "Аккаунт {account} разблокирован"
  },
  "uk": {
    "BLOCK_BTN": "Заблокувати акаунт",
    "UNBLOCK_BTN": "Розблокувати акаунт",
    "ACCOUNT_BLOCKED_MSG": "Акаунт {account} заблоковано",
    "ACCOUNT_UNBLOCKED_MSG": "Акаунт {account} розблоковано"
  }
}
</i18n>
