<template>
  <div class="account-cell-status">
    <p
      class="account-cell-status__val accounts-cmn__str accounts-cmn__str_nb"
      :class="{ 'account-cell-status__val_active': isActive }"
    >
      <template v-if="isActive">
        {{ $t('ACTIVE_STATUS') }}
      </template>

      <template v-else-if="!account.isConnectorAvailable">
        {{ $t('INVALID_CREDENTIALS_STATUS') }}
      </template>

      <template v-else>
        {{ $t('BLOCKED_STATUS') }}
      </template>
    </p>
  </div>
</template>

<script>
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  name: 'account-cell-status',

  props: {
    account: { type: ExtensionAccount, required: true },
  },

  computed: {
    isActive () {
      return this.account.isConnectorAvailable && this.account.isLoginAllowed
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/accounts";

.account-cell-status {
  &__val {
    color: $color-flag-is-error;

    &_active {
      color: $color-flag-is-success;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ACTIVE_STATUS": "Active",
    "INVALID_CREDENTIALS_STATUS": "Invalid credentials",
    "BLOCKED_STATUS": "Blocked"
  },
  "ka": {
    "ACTIVE_STATUS": "აქტიური",
    "INVALID_CREDENTIALS_STATUS": "არასწორი მონაცემები",
    "BLOCKED_STATUS": "დაბლოკილი"
  },
  "ru": {
    "ACTIVE_STATUS": "Активный",
    "INVALID_CREDENTIALS_STATUS": "Неверный пароль",
    "BLOCKED_STATUS": "Заблокирован"
  },
  "uk": {
    "ACTIVE_STATUS": "Активний",
    "INVALID_CREDENTIALS_STATUS": "Неправильний пароль",
    "BLOCKED_STATUS": "Заблокований"
  }
}
</i18n>
