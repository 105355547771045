<template>
  <div class="accounts">
    <page-subnav class="accounts__subnav">
      <ui-button
        class="accounts__create-btn"
        @click="isAccountCreateModalShown = true"
      >
        {{ $t('CREATE_ACCOUNT_BTN') }}
        <ui-icon icon="plus" />
      </ui-button>

      <accounts-list-params
        class="accounts__subnav-action"
        :value="{
          minBidLimit: $route.query.minBidLimit || '',
          maxBidLimit: $route.query.maxBidLimit || '',
          isInvalidCredentials: $route.query.isInvalidCredentials === 'true',
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="accounts__subnav-action accounts__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />
    </page-subnav>

    <accounts-list
      class="accounts__list"
      :list="list"
      :load-more="loadMore"
      @update-list-ask="loadList"
      @more-ask="loadMore"
    />

    <account-create-modal
      v-if="isAccountCreateModalShown"
      class="accounts__create-modal"
      @close="isAccountCreateModalShown = false"
    />
  </div>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import AccountsList from './components/AccountsList'
import AccountsListParams from './components/AccountsListParams'
import AccountCreateModal from './components/AccountCreateModal'

import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
  impactAccountsActions,
  impactAccountsMutations,
  impactAccountsGetters
} from './store/types'

import { showError } from 'Utils/notifications'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

export default {
  name: 'accounts',
  components: {
    PageSubnav,
    SubnavSearch,
    UiButton,
    UiIcon,
    AccountsList,
    AccountsListParams,
    AccountCreateModal,
  },

  data () {
    return {
      isAccountCreateModalShown: false,
    }
  },

  computed: {
    ...mapGetters('ui/impact-accounts', {
      list: impactAccountsGetters.LIST,
      isLoading: impactAccountsGetters.IS_LOADING,
    }),

    listPayload () {
      return {
        filter: {
          search: this.$route.query.search || undefined,
          minBidLimit: this.$route.query.minBidLimit || undefined,
          maxBidLimit: this.$route.query.maxBidLimit || undefined,
          isConnectorAvailable: this.$route.query.isInvalidCredentials === 'true'
            ? false
            : undefined,
        }
      }
    }
  },

  async created () {
    await this.loadList()
  },

  methods: {
    ...mapActions('ui/impact-accounts', {
      loadListAction: impactAccountsActions.LOAD_LIST,
      loadMore: impactAccountsActions.LOAD_MORE,
    }),

    ...mapMutations('ui/impact-accounts', {
      setIsLoading: impactAccountsMutations.SET_IS_LOADING,
    }),

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.minBidLimit = input.minBidLimit || undefined
      query.maxBidLimit = input.maxBidLimit || undefined
      query.isInvalidCredentials = input.isInvalidCredentials || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }
      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadList()
    },

    async loadList () {
      try {
        await this.loadListAction(this.listPayload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.accounts {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>

<i18n>
{
  "en": {
    "CREATE_ACCOUNT_BTN": "New",
    "META_TITLE": "Accounts",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the accounts list. Please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_ACCOUNT_BTN": "ახალი",
    "META_TITLE": "ექაუნთები",
    "LIST_FETCH_FAILED_NOTIFY": "ექაუნთები სია ვერ ჩაიტვირთა. მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CREATE_ACCOUNT_BTN": "Новый",
    "META_TITLE": "Аккаунты",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список аккаунтов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_ACCOUNT_BTN": "Новий",
    "META_TITLE": "Акаунти",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список акаунтів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
