import Vue from 'vue'

import cloneDeep from 'lodash/cloneDeep'
import mapValues from 'lodash/mapValues'

/**
 * Generates form schema from provided form fields.
 *
 * @template FieldKey,FieldValue
 *
 * @param {object} opts Options.
 * @param {Record<FieldKey, FieldValue>} opts.form Form fields.
 * @param {object} [opts.validators] Form validators.
 *
 * @returns {{
 *   form: Record<FieldKey, FieldValue>,
 *   formErrors: Record<FieldKey, FieldValue[]>,
 *   validateForm(): boolean,
 *   resetFields(): void
 * }} Form schema.
 */
export function createFormSchema ({ form, schema, validators }) {
  const formEntry = cloneDeep(form)
  const formSchema = Vue.observable({
    form: new Proxy(formEntry, {
      set (_, key) {
        if (key in formEntry) {
          formSchema.formErrors[key] = schema ? [] : ''
        }

        return Reflect.set(...arguments)
      }
    }),
    formErrors: mapValues(formEntry, () => schema ? [] : ''),

    validateForm () {
      const validationByField = Object.entries(this.form)
        .map(([field, value]) => {
          const normalizedValidators = validators[field] || []
          const fieldValidators = Array.isArray(normalizedValidators)
            ? normalizedValidators
            : [normalizedValidators]

          for (const validator of fieldValidators) {
            const { isValid, translationId } = validator(value, formSchema.form)

            if (!isValid) {
              this.formErrors[field] = translationId
              return false
            }
          }

          this.formErrors[field] = ''
          return true
        })

      return validationByField.every(val => val)
    },

    resetFields () {
      Object.entries(form).forEach(([key, value]) => {
        formSchema.form[key] = cloneDeep(value)
        formSchema.formErrors[key] = schema ? [] : ''
      })
    },
  })

  return formSchema
}
