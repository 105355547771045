var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-portal',[_c('ui-modal',{staticClass:"account-create-modal",attrs:{"title":_vm.$t('CREATE_ACCOUNT_TITLE'),"show-close-btn":!_vm.isCreating},on:{"close":_vm.close}},[_c('form',{staticClass:"account-create-modal__form",class:{ 'account-create-modal__form_loading': _vm.isCreating },on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"account-create-modal__field"},[_vm._v("\n        "+_vm._s(_vm.$t('IMPACT_LOGIN_LBL'))+"\n        "),_c('ui-text',{staticClass:"account-create-modal__field-input",attrs:{"placeholder":_vm.$t('IMPACT_LOGIN_PH'),"is-error":Boolean(_vm.formErrors.login)},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t(_vm.formErrors.login, {
              maxLength: _vm.MAX_LOGIN_LENGTH
            }))+"\n          ")]},proxy:true}]),model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}})],1),_vm._v(" "),_c('div',{staticClass:"account-create-modal__field"},[_vm._v("\n        "+_vm._s(_vm.$t('COUNTRY_LBL'))+"\n        "),_c('ui-select',{staticClass:"account-create-modal__field-input",attrs:{"fill":"frame","placeholder":_vm.$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH'),"options":_vm.countryOptions,"is-error":Boolean(_vm.formErrors.country)},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t(_vm.formErrors.country))+"\n          ")]},proxy:true}]),model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1),_vm._v(" "),_c('div',{staticClass:"account-create-modal__field"},[_vm._v("\n        "+_vm._s(_vm.$t('BID_LIMIT_LBL'))+"\n        "),_c('ui-text',{staticClass:"account-create-modal__field-input",attrs:{"type":"number","placeholder":_vm.$t('BID_LIMIT_PH'),"is-error":Boolean(_vm.formErrors.bidLimit)},scopedSlots:_vm._u([{key:"dock-left",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.bidLimitCurrencySymbol)+"\n          ")]},proxy:true},{key:"error",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t(_vm.formErrors.bidLimit, {
              min: _vm.MIN_BID_LIMIT
            }))+"\n          ")]},proxy:true}]),model:{value:(_vm.form.bidLimit),callback:function ($$v) {_vm.$set(_vm.form, "bidLimit", $$v)},expression:"form.bidLimit"}})],1),_vm._v(" "),_c('div',{staticClass:"account-create-modal__field"},[_vm._v("\n        "+_vm._s(_vm.$t('PASSWORD_LBL'))+"\n        "),_c('ui-text',{staticClass:"account-create-modal__field-input",attrs:{"type":"password","placeholder":_vm.$t('PASSWORD_PH'),"is-error":Boolean(_vm.formErrors.password)},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t(_vm.formErrors.password, {
              minLength: _vm.MIN_PASSWORD_LENGTH,
              maxLength: _vm.MAX_PASSWORD_LENGTH,
            }))+"\n          ")]},proxy:true}]),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_vm._v(" "),_c('div',{staticClass:"account-create-modal__field"},[_vm._v("\n        "+_vm._s(_vm.$t('PASSWORD_CONFIRM_LBL'))+"\n        "),_c('ui-text',{staticClass:"account-create-modal__field-input",attrs:{"type":"password","placeholder":_vm.$t('PASSWORD_CONFIRM_PH'),"is-error":Boolean(_vm.formErrors.passwordConfirm)},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t(_vm.formErrors.passwordConfirm))+"\n          ")]},proxy:true}]),model:{value:(_vm.form.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirm", $$v)},expression:"form.passwordConfirm"}})],1),_vm._v(" "),_c('div',{staticClass:"account-create-modal__buttons"},[_c('ui-button',{staticClass:"account-create-modal__btn",attrs:{"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('CREATE_ACCOUNT_BTN'))+"\n        ")]),_vm._v(" "),_c('ui-button',{staticClass:"account-create-modal__btn",attrs:{"type":"button","look":"secondary","fill":"frame-hover"},on:{"click":_vm.close}},[_vm._v("\n          "+_vm._s(_vm.$t('CANCEL_BTN'))+"\n        ")])],1)]),_vm._v(" "),_c('ui-spinner',{staticClass:"account-create-modal__loader",attrs:{"overlay":"","type":"pills","show":_vm.isCreating}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }